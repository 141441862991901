import Vue from "vue";
import Vuex from "vuex";
import * as types from "./types";

Vue.use(Vuex);

const initialStore = {
    access: false,
};

export default new Vuex.Store({
    state: {
        ...initialStore,
    },
    mutations: {
        [types.MUTATE_SET_ACCESS]: (state, access) => {
            state.access = access;
        },
    },
    actions: {
        [types.SET_ACCESS]: ({ commit }, access) => {
            commit(types.MUTATE_SET_ACCESS, access);
        },
    },
});
