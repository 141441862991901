<template>
    <div>
        <h3>Welcome to the carboncap admin panel.</h3>
        <p v-if="!loading">
            {{
                `Total emissions in production accounts: ${Math.round(
                    total.value
                ).toLocaleString()} ${total.unit}`
            }}
        </p>
        <b-table-simple v-if="!loading">
            <b-thead head-variant="light">
                <b-tr>
                    <b-th>Year</b-th>
                    <b-th>Emissions</b-th>
                    <b-th>Unit</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="e in emissions" :key="e.year">
                    <b-td>{{ e.year }}</b-td>
                    <b-td>{{ e.emissions?.toLocaleString() ?? 0 }}</b-td>
                    <b-td>{{ e.unit }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <br />

        <p>Emissions per production account (tCO2e)</p>
        <b-table
            v-if="!loading"
            hover
            striped
            sort-icon-left
            :items="emissionsDetail.emissions"
            :fields="detailFields"
        >
            <template
                v-for="year in emissionsDetail.years"
                v-slot:[`cell(${year})`]="{ item }"
            >
                {{ item[year] ? item[year].toLocaleString() : 0 }}
            </template>
        </b-table>
    </div>
</template>

<script>
import insightsApi from "@/api/admin_insights";
export default {
    name: "CCAdminHome",
    data() {
        return {
            loading: true,
            emissions: [],
            total: { value: null, unit: "" },
            emissionsDetail: { years: [], emissions: [] },
            detailFields: [],
        };
    },
    async created() {
        const result = await insightsApi.totalsPerYear();
        const resultTotal = await insightsApi.total();
        const resTotalPerCompany = await insightsApi.totalsPerYearPerCompany();

        this.emissions = result.data;
        this.total = resultTotal.data;
        this.emissionsDetail = resTotalPerCompany.data;
        this.setDetailTable();
        this.loading = false;
    },

    methods: {
        setDetailTable() {
            const companyField = { key: "company_name", sortable: true };
            const yearFields = this.emissionsDetail.years.map((y) => {
                return { key: y.toString(), sortable: true };
            });
            yearFields.unshift(companyField);
            this.detailFields = yearFields;
        },
    },
};
</script>
