import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import superAdminApi from "../api/superadmin";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/users",
        name: "Users",
        component: () => import("@/views/Users.vue"),
    },
    {
        path: "/companies",
        name: "Companies",
        component: () => import("@/views/Companies.vue"),
    },
    {
        path: "/import",
        name: "Import",
        component: () => import("@/views/Import.vue"),
    },
    {
        path: "/emission-factors",
        name: "Emission Factors",
        component: () => import("@/views/EmissionFactors.vue"),
    },
    {
        path: "/frameworks",
        name: "Frameworks",
        component: () => import("@/views/FrameworksView.vue"),
    },
    {
        path: "/topics",
        name: "Topics",
        component: () => import("@/views/TopicsView.vue"),
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import("@/views/Admins.vue"),
        beforeEnter: async (to, from, next) => {
            const access = await superAdminApi.checkAccess();

            if (access) next();
            else next({ name: Home });
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: window.env.BASE_URL,
    routes,
});

export default router;
