import http from "../http-common";

const api = {
    total: () => {
        return http.get(`/admin-insights/total`);
    },
    totalsPerYear: () => {
        return http.get(`/admin-insights/total-per-year`);
    },
    totalsPerYearPerCompany: () => {
        return http.get(`/admin-insights/total-per-company-per-year`);
    },
};

export default api;
