<template>
    <div id="app">
        <template v-if="loading">
            <div class="text-center mt-4">
                <b-spinner
                    variant="primary"
                    type="grow"
                    label="Spinning"
                ></b-spinner>
            </div>
        </template>
        <template v-else-if="!access">
            <no-access></no-access>
        </template>
        <template v-else>
            <navbar></navbar>
            <div class="m-4">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </template>
    </div>
</template>

<script>
import { getInstance } from "./auth";
import Navbar from "@/components/navigation/Navbar";
import NoAccess from "@/components/auth/NoAccess";
import adminApi from "./api/admin.js";

import { mapState } from "vuex";
import * as types from "@/store/types";

export default {
    components: { Navbar, NoAccess },
    computed: {
        ...mapState({
            access: (state) => state.access,
        }),
    },
    data() {
        return {
            loading: true,
        };
    },
    methods: {
        async init() {
            if (!this.$auth.isAuthenticated) return;

            await this.loadTokenIntoStore();
            await this.checkAdmin();
        },
        async loadTokenIntoStore() {
            const instance = getInstance();
            try {
                const authToken = await instance.getTokenSilently();
                sessionStorage.setItem("auth_token", authToken);
            } catch (error) {
                console.log(error);
            }
        },
        async checkAdmin() {
            try {
                const response = await adminApi.checkAccess();
                this.$store.dispatch(types.SET_ACCESS, response.data);
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        "$auth.loading": {
            handler(val) {
                this.loading = val;
                if (!val) this.init();
            },
        },
        "$auth.isAuthenticated": {
            handler(val) {
                if (val) this.init();
            },
        },
    },
};
</script>
