import http from "../http-common";

const api = {
    checkAccess: () => {
        return http.get("/super/check-access");
    },

    getAll: () => {
        return http.get(`/super/`);
    },

    create: (user) => {
        return http.post("/super", user);
    },
};

export default api;
