// Set your environment variables here. Are you looking at an env variable and thinking
// "oops, this should not be visible": don't put it here. This is a client-side
// file and can be seen by anyone. If you need to hide something, you probably
// don't want to set it like this anyway. Backend will be more appropriate...

const variables = {
    common: {},
    production: {
        NODE_ENV: "production",
        auth0_domain: "carboncap-admin.eu.auth0.com",
        auth0_client_id: "1imLx1MVL2Qi90875WCdE0Hew9V9JjQe",
        auth0_audience: "https://admin.carboncap.be/api/",
    },
    development: {
        NODE_ENV: "development",
        auth0_domain: "carboncap-dev.eu.auth0.com",
        auth0_client_id: "L7abzXQtI8GIFDvffdvGmi5PpfGOH7QH",
        auth0_audience: "https://admin-staging.carboncap.be/",
    },
    staging: {
        NODE_ENV: "production", // Staging uses production env and dev auth0
        auth0_domain: "carboncap-dev.eu.auth0.com",
        auth0_client_id: "L7abzXQtI8GIFDvffdvGmi5PpfGOH7QH",
        auth0_audience: "https://admin-staging.carboncap.be/",
    },
};

const setVariables = () => {
    let env = "production"; // Default to production
    const url = window.location;

    // We include loopback because Vite uses it instead of localhost (by default)
    if (url.href.includes("localhost") || url.href.includes("127.0.0.1")) {
        env = "development";
    }

    if (url.href.includes("staging")) {
        env = "staging";
    }

    window.env = { ...variables.common, ...variables[env] };
};

setVariables();
