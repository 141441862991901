// Inject environment variables. Needs to be the very first thing in this file.
import "./util/variables";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import decimals from "./util/decimals";

Vue.config.productionTip = false;

// CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/application.scss";

// AUTH0
import { Auth0Plugin } from "./auth";

const domain = window.env.auth0_domain;
const clientId = window.env.auth0_client_id;
const audience = window.env.auth0_audience;

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
});

// PLUGINS
Vue.use(BootstrapVue);
Vue.use(decimals);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
