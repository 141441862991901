<template>
    <div>
        <b-navbar toggleable="lg" type="dark" class="cc-navbar">
            <b-navbar-brand to="/">Carboncap - Admin</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/users">Users</b-nav-item>
                    <b-nav-item to="/companies">Companies</b-nav-item>
                    <b-nav-item to="/emission-factors"
                        >Emission factors</b-nav-item
                    >
                    <b-nav-item-dropdown text="Frameworks" v-if="false">
                        <b-dropdown-item to="/frameworks">
                            Frameworks
                        </b-dropdown-item>
                        <b-dropdown-item to="/topics">Topics</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item to="/admin" v-if="superadmin">Admin</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item
                        v-if="!$auth.loading"
                        right
                        href="#"
                        @click="logout"
                        >Log out</b-nav-item
                    >
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import * as types from "@/store/types";

import superAdminApi from "@/api/superadmin";

export default {
    name: "CCAdminNavbar",
    data() {
        return {
            superadmin: false,
        };
    },
    created() {
        this.checkSuperAdmin();
    },
    methods: {
        logout() {
            this.$store.dispatch(types.SET_ACCESS, false);
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
        async checkSuperAdmin() {
            try {
                const result = await superAdminApi.checkAccess();
                this.superadmin = result.data;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
