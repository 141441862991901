<template>
    <div
        class="d-flex flex-column align-items-center justify-content-center mt-4"
    >
        Sorry, you have no access to this tool. Think this is a mistake? Mail us
        at info@carboncap.be <template v-if="canLogin">or login here</template>
        <div class="mt-4">
            <!-- show login when not authenticated -->
            <b-button variant="primary" v-if="canLogin" @click="login"
                >Log in</b-button
            >
            <b-button
                variant="primary"
                v-if="$auth.isAuthenticated"
                @click="logout"
            >
                Log out
            </b-button>
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";

export default {
    data() {
        return {
            canLogin: !this.$auth.isAuthenticated,
        };
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
        logout() {
            this.$store.dispatch(types.SET_ACCESS, false);
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
    },
    watch: {
        "$auth.isAuthenticated": {
            handler(val) {
                console.log("hit", val);
                this.canLogin = !val;
            },
        },
    },
};
</script>
