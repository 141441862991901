const axios = require("axios");

// Default config options
const defaultOptions = {
    baseURL: getBaseURL(),
    headers: {
        "Content-Type": "application/json",
    },
};

// Create instance
let axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
    (request) => {
        request.headers["CompanyId"] =
            sessionStorage.getItem("selectedCompany");
        request.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
            "auth_token"
        )}`;
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function getLocation(href) {
    const match = href.match(
        /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
    );
    return (
        match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7],
        }
    );
}

function getHost() {
    const url = "" + window.location;
    const location = getLocation(url);
    if (location && location.protocol && location.hostname) {
        const host = location.protocol + "//" + location.hostname;
        return host;
    }
}

function getBaseURL() {
    if (window.env.NODE_ENV === "production") {
        return `${getHost()}/api`;
    } else return `${getHost()}:8082/api`;
}

export default axiosInstance;
