export const checkDecimals = (number) => {
    // Remove current decimals from the number
    const rounded = Math.round(number).toString();
    let fraction = 0;

    // Check for the length of the remaining string
    switch (rounded.length) {
        case 1:
            fraction = 2;
            break;
        case 2:
            fraction = 1;
            break;
        default:
            fraction = 0;
            break;
    }

    // Localise the number and add the number of decimals according to the fraction var, if the output is NaN, just return the input
    const output = Number.parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
    });
    if (output === "NaN") return number;
    return output;
};

const decimals = {
    install: (Vue) => {
        Vue.prototype.$checkDecimals = (number) => checkDecimals(number);
    },
};

export default decimals;
